<template>
    <v-container fluid fill-height>
        <v-layout align-center justify-center class="flex-fill">
            <v-flex xs12 sm8 justify-center align-center d-flex box-shadow>
                <div class="login-form elevation-10">
                    <div class="sijbers-support-logo mb-10">
                        <img src="@/assets/images/logo-sijbers-support.png" />
                    </div>
                    <h1 class="main-title mb-2">Voer uw inloggegevens in</h1>
                    <v-form ref="form" @submit.prevent="checkLogin">
                        <v-card class="elevation-0 transparent">
                            <v-card-text class="py-0">
                                <v-text-field
                                    type="email"
                                    outlined
                                    hide-details="auto"
                                    single-line
                                    v-model="username"
                                    label="E-mailadres"
                                    :rules="usernameRules"
                                    required
                                    class="mb-2"
                                ></v-text-field>
                                <v-text-field
                                    type="password"
                                    outlined
                                    hide-details="auto"
                                    single-line
                                    v-model="password"
                                    label="Wachtwoord"
                                    :rules="passwordRules"
                                    required
                                    class="mb-5"
                                ></v-text-field>
                                <v-btn type="submit" block :loading="submitting" :disabled="submitting || username.length < 1 || password.length < 1" class="mb-2 elevation-0">Inloggen</v-btn>
                                <v-btn @click="openForgotPassword" text block color="black">Wachtwoord vergeten?</v-btn>
                            </v-card-text>
                        </v-card>
                    </v-form>
                </div>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
export default {
    name: "AuthLogin",
    data: () => ({
        submitting: false,
        username: '',
        usernameRules: [
            v => !!v || 'E-mailadres is verplicht'
        ],
        password: '',
        passwordRules: [
            v => !!v ||'Wachtwoord is verplicht'
        ],
    }),
    mounted () {
        if (this.$store.state.auth.isLoggedIn) {
            this.$router.push({name: "DashboardView"});
        }
    },
    methods: {
        openForgotPassword () {
            this.$router.push({name: "ForgotPassword"})
        },
        checkLogin () {
            // Check if form is valid
            if (!this.$refs.form.validate()) {
                return;
            }

            this.submitting = true;

            // Submit data to auth store
            this.$store.dispatch('login', {
                username: this.username,
                password: this.password,
                _remember_me: true,
            }).then((success) => {
                if (success) {
                    this.$router.push({name: "DashboardView"})
                } else {
                    return this.$store.dispatch('showSnackbar', { color: 'error', text: 'Ongeldige inloggegevens'});
                }
            }).catch((e) => {
                console.error(e);
                return this.$store.dispatch('showSnackbar', { color: 'error', text: 'Ongeldige inloggegevens' });
            }).finally(() => {
                this.submitting = false;
            });
        },
    },

}
</script>

<style scoped>

</style>
